import React, { useEffect, useRef } from 'react';

const images = [
    '/gallery-3.png',
    '/gallery-1.png',
    '/gallery-2.png',
    '/gallery-4.png',
];

const overlayImage = '/logo412.png';

function Gallery() {
    const scrollContainer = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollContainer.current) {
                const scrollTop = window.scrollY;
                scrollContainer.current.scrollLeft = scrollTop;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <section className="py-2 overflow-hidden">
            
            <div
                ref={scrollContainer}
                className="flex space-x-4 overflow-x-auto scrolling-touch snap-x snap-mandatory no-scrollbar"
                style={{
                    scrollBehavior: 'smooth',
                    transition: 'scroll-behavior 0.1s ease-out',
                }}
            >
                {images.map((src, index) => (
                    <img
                        key={index}
                        src={src}
                        alt={`Design example ${index + 1}`} // Corrected the alt attribute
                        className="w-2/3 sm:w-1/2 md:w-1/3 lg:w-1/4 h-auto rounded-lg shadow-md snap-center"
                    />
                ))}
            </div>
        </section>
    );
}

export default Gallery;
