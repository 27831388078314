import React from 'react';

const services = [
    { title: 'Websites', color: 'bg-blue-500' },
    { title: 'Landing pages', color: 'bg-pink-500' },
    { title: 'Logos', color: 'bg-green-500' },
    { title: 'Social media', color: 'bg-red-500' },
    { title: 'Infographics', color: 'bg-yellow-500' },
];

function Services() {
    return (
        <section className="py-16 text-center">
            <h3 className="text-4xl font-bold text-center mb-12 font-serif">Our services</h3>
            <div className="flex flex-wrap justify-center gap-4">
                {services.map((service, index) => (
                    <div
                        key={index}
                        className={`${service.color} text-white px-4 py-2 rounded-md text-lg font-semibold`}
                    >
                        {service.title}
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Services;
