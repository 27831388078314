import React from 'react';

function FAQ() {
    const faqs = [
        {
            question: 'What is the difference between the Silver, Gold, and Diamond plans?',
            answer: 'The Silver plan is a one-time payment and is best suited if you need a one-page website. The Gold plan is a monthly subscription that is ideal for larger projects. The Diamond plan is our premium offering. If you want dedicated support for your needs, this is the plan for you.',
        },
        {
            question: 'What do you mean by 2 business days delivery?',
            answer: 'It means that we complete up to 3 requests every 2 business days on the Diamond plan.',
        },
        {
            question: 'What is a request?',
            answer: 'A request can be adding a section to the website, working on a visual, or doing market research.',
        },
        {
            question: 'Can you build websites from scratch?',
            answer: 'Yes, we can create a website from the ground up tailored to your needs.',
        },
        {
            question: 'Can you revamp our website?',
            answer: 'Absolutely! We specialize in modernizing websites to be more responsive, visually appealing, and faster.',
        },
        {
            question: 'How does it work?',
            answer: 'We start with an initial call where you explain your requirements. Depending on your website size and the plan you choose, we will deliver the first version in 2-5 days.',
        },
        {
            question: 'Can you create just a landing page?',
            answer: 'Yes, we can design a focused, high-converting landing page for your business.',
        },
        {
            question: 'Can you set up email collection?',
            answer: 'Certainly! We can integrate email collection forms and tools to help you grow your mailing list.',
        },
        {
            question: 'Can you set up a database?',
            answer: 'Yes, we can set up and manage databases to support your website functionality.',
        },
        {
            question: 'How do I cancel?',
            answer: 'Just send us an email, and we will handle the cancellation process for you.',
        },
        {
            question: 'What if I am not satisfied?',
            answer: 'We will revise the design until you are 100% satisfied with the final result.',
        },
        {
            question: 'Do you have a referral program?',
            answer: 'Yes, we have a generous referral program. If you refer a client to us, we will pay you 20% of their first sale. For example, if you refer a friend to our service and they choose the Diamond plan, you will receive $500.',
        }
    ];

    return (
        <section className="py-16 bg-gray-100">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                <h3 className="text-3xl font-bold mb-8 text-center">Frequently Asked Questions</h3>
                <div className="space-y-6">
                    {faqs.map((faq, index) => (
                        <div key={index} className="bg-white p-6 rounded-lg shadow">
                            <h4 className="text-xl font-semibold mb-2">{faq.question}</h4>
                            <p className="text-gray-700">{faq.answer}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default FAQ;
