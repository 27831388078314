import React from 'react';

const steps = [
    {
        title: 'Market Research',
        subtitle: 'We analyze your market, identify competitors, and uncover customer insights.',
        number: '1',
    },
    {
        title: 'Design',
        subtitle: 'We create captivating designs and craft compelling content to make your brand stand out.',
        number: '2',
    },
    {
        title: 'Launch & Scale',
        subtitle: 'We help you launch your site and grow your business with continuous support and optimization.',
        number: '3',
    },
];

const UserJourney = () => {
    return (
        <div className="relative py-16 mt-6">
            <h3 className="text-4xl font-bold text-center mb-12 font-serif">How it works</h3>
            <div className="max-w-4xl mx-auto text-center">
                {steps.map((step, index) => (
                    <div key={index} className="relative flex flex-col items-center mb-16">
                        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-10 z-10">
                            <div className="text-9xl font-bold text-gray-200">{step.number}</div>
                        </div>
                        <div className="relative z-20">
                            <h3 className="text-2xl font-semibold text-gray-900">{step.title}</h3>
                            <p className="text-gray-600 mt-4">{step.subtitle}</p>
                        </div>
                        {index < steps.length - 1 && (
                            <div className="flex justify-center mt-6">
                                <div className="text-gray-600 text-4xl">↓</div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserJourney;