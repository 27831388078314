import React, { useState } from 'react';

function PricingTable() {
    const [plan, setPlan] = useState('Gold');  // Set Gold as the default plan

    // Calculate the original price before the 20% discount
    const pricing = {
        Silver: {
            originalPrice: '$1,244',
            discountedPrice: '$995',
            features: [
                'For 1-page website only',
                'Max 5 revisions',
                '5 business days delivery',
                '<strong>1 time payment</strong>',
            ],
            url: 'https://buy.stripe.com/8wM16ydZL6uzeaI4gg',  // Silver plan URL
        },
        Gold: {
            originalPrice: '$1,869/m',
            discountedPrice: '$1,495/m',
            features: [
                '<strong>Revisions until satisfied</strong>',
                '1 request at a time',
                '3 business days delivery',
                'Pause or cancel anytime after the first month',
            ],
            url: 'https://buy.stripe.com/4gwcPgcVH6uz4A8bIJ',  // Gold plan URL
        },
        Diamond: {
            originalPrice: '$3,744/m',
            discountedPrice: '$2,995/m',
            features: [
                '<strong>Revisions until satisfied</strong>',
                '3 requests at a time',
                '<strong>2 business days delivery</strong>',
                'Pause or cancel anytime after the first month',
            ],
            url: 'https://buy.stripe.com/8wM9D4f3P7yDgiQ3cf',  // Diamond plan URL
        },
    };

    const handleGetStarted = () => {
        // Redirect to the URL based on the selected plan
        window.location.href = pricing[plan].url;
    };

    return (
        <section id="pricing-table" className="py-16 pl-2 pr-2 relative">
            {/* Promotion Banner */}
            <div className="absolute top-0 left-0 w-full bg-red-500 text-white py-2 text-center font-semibold">
                20% Launch Promotion valid until December 31!
            </div>

            <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden mt-8 relative">
                <div className="p-8 text-center">
                    <h3 className="text-3xl font-bold mb-4">Plans</h3>

                    <div className="flex justify-between mb-6 w-full">
                        <button
                            onClick={() => setPlan('Silver')}
                            className={`flex-grow px-2 py-2 font-semibold rounded-l-md ${plan === 'Silver' ? 'border-black border bg-gray-200 text-black' : 'bg-gray-100 text-gray-700'}`}
                        >
                            Silver
                        </button>
                        <button
                            onClick={() => setPlan('Gold')}
                            className={`flex-grow px-2 py-2 font-semibold ${plan === 'Gold' ? 'border-black border bg-yellow-400 text-black' : 'bg-yellow-200 text-gray-700'}`}
                        >
                            Gold
                        </button>
                        <button
                            onClick={() => setPlan('Diamond')}
                            className={`flex-grow px-2 py-2 font-semibold rounded-r-md ${plan === 'Diamond' ? 'border-black border bg-blue-400 text-black' : 'bg-blue-200 text-gray-700'}`}
                        >
                            <span className="mr-2">💎</span> Diamond
                        </button>
                    </div>

                    {/* Display original and discounted prices */}
                    <p className="text-xl text-gray-500 line-through mb-2">{pricing[plan].originalPrice}</p>
                    <p className="text-5xl font-bold text-black mb-2">{pricing[plan].discountedPrice}</p>
                    <p className="text-sm text-green-600 font-semibold">(20% Off!)</p>

                    <hr className="border-gray-300 mb-8" />

                    <ul className="text-left mb-8 space-y-4">
                        {pricing[plan].features.map((feature, index) => (
                            <li key={index} dangerouslySetInnerHTML={{ __html: `• ${feature}` }} />
                        ))}
                    </ul>

                    <button
                        onClick={handleGetStarted}
                        className="w-full py-3 bg-black text-white font-semibold rounded-md hover:bg-gray-800 transition duration-200"
                    >
                        Get started
                    </button>

                    <p className="mt-4 text-gray-500">
                        or <a href="https://calendly.com/lennie-zaynard/30min" className="text-black font-semibold underline">book a call</a>
                    </p>
                    <p className="mt-2 text-gray-500">
                        or email us: <a href="mailto:lennie@zaynard.com" className="text-black font-semibold underline">lennie@zaynard.com</a>
                    </p>
                </div>
            </div>
        </section>
    );
}

export default PricingTable;
