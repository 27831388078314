import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Gallery from './components/Gallery';
import Features from './components/Features';
import ServicesSection from './components/ServicesSection';
import UserJourney from './components/UserJourney'; // Import the new component
import PricingTable from './components/PricingTable';
import Faq from './components/Faq';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';

function App() {
    return (
        <Router>
            <div className="min-h-screen bg-white flex flex-col h-screen">
                <Header />
                <div className="flex-1 pt-0 px-4 sm:px-2 md:px-8 pb-4 sm:pb-2 md:pb-8 flex">
                    <div className="bg-gray-100 bg-dots rounded-3xl shadow-lg w-full h-full max-w-4xl mx-auto">
                        <Routes>
                            <Route path="/" element={
                                <>
                                    <Hero />
                                    <Gallery />
                                    <Features />
                                    <ServicesSection />
                                    <UserJourney /> {/* Added new section here */}
                                    <PricingTable />
                                    <Faq />
                                    <Footer />
                                </>
                            } />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                        </Routes>
                    </div>
                </div>

            </div>
        </Router>
    );
}

export default App;
