import React from 'react';

function Footer() {
    return (
        <section className="py-8 bg-gray-100">
            <div className="max-w-4xl mx-auto text-center">
                <p className="text-gray-600">
                    <a href="mailto:lennie@zaynard.com" className="text-black font-semibold underline mx-4">Contact</a>
                    |
                    <a href="/terms-and-conditions" className="text-black font-semibold underline mx-4">Terms & Conditions</a>
                    |
                    <a href="/privacy-policy" className="text-black font-semibold underline mx-4">Privacy Policy</a>
                </p>
            </div>
        </section>
    );
}

export default Footer;
