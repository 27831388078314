import React from 'react';

const features = [
    {
        title: 'Subscribe',
        description: 'Subscribe to one of our plans',
        image: '/subscribe.png', // Update this path with your actual image path
    },
    {
        title: 'Receive',
        description: 'Receive your first design as fast as within 2 business days',
        image: '/receive.png', // Update this path with your actual image path
    },
    {
        title: 'Revise',
        description: "We'll revise the design until you're 100% satisfied",
        image: '/revise.png', // Update this path with your actual image path
    },
];


function Features() {
    return (
        <section className="py-16">
            <h3 className="text-4xl font-bold text-center mb-12 font-serif">Your local design expert.</h3>
            <p className="text-lg text-center mb-12 text-gray-600">No hidden fees.</p>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {features.map((feature, index) => (
                    <div key={index} className="text-center">
                        <img
                            src={feature.image}
                            alt={feature.title}
                            className="w-16 h-16 mx-auto mb-4 rounded-full object-cover"
                        />
                        <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
                        <p>{feature.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}


export default Features;