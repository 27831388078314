import React from 'react';

function Hero() {
    return (
        <>
            <section className="pt-20 pb-10 sm:pt-28 sm:pb-14 text-center px-4 sm:px-6 md:px-8">
                <h2 className="text-6xl font-bold mb-4 font-serif">Design made simple</h2>
                <p className="text-xl mb-8 font-sans pt-6">Your new website in less than a week.</p>
                <a href="#pricing-table">
                    <button className="bg-black text-white px-16 py-5 text-xl font-semibold rounded-lg transform transition-all duration-300 hover:scale-105 mt-4" style={{ boxShadow: '0 10px 25px rgba(0, 0, 0, 0.8)' }}>
                        See plans
                    </button>
                </a>

                <div className="flex justify-center items-center mt-6 mb-2"> {/* Reduced margin-bottom */}
                    <span className="h-4 w-4 bg-red-500 rounded-full animated-dot"></span>
                    <span className="ml-2 text-gray-700">Available now</span>
                </div>
            </section>

            <section className="mb-8">
                <div className="w-full bg-red-500 text-white py-2 text-center font-semibold text-sm">
                    Get up to $500/client you bring us.
                </div>

            </section>

        </>
    );
}

export default Hero;
